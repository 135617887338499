import { BrowserRouter } from "react-router-dom";
import CommonRoutes from "./Router/Routes/CommonRoutes/CommonRoutes";

function App() {
  return (
    <>
      <BrowserRouter>
        <CommonRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;

export function changeTitleAndMeta() {
  const pathname = window.location.pathname;
  let title = "";
  let description = "";

  // switch (pathname) {
  //   case COMMON_ROUTES.PRODUCT_CAR:
  //     title = "Affordable Car Insurance Quotes - Find the Best Rates Today";
  //     description =
  //       "Explore the most affordable car insurance quotes and find the best rates today. Compare policies on the best car insurance comparison sites for the lowest car insurance policy prices.";
  //     break;

  //   case COMMON_ROUTES.PRODUCT_TW:
  //     title =
  //       "Get the Best Two Wheeler Insurance - Quick Bike Insurance Renewal Online";
  //     description =
  //       "Explore the best bike insurance online! Secure your ride with our quick and easy policy. Bike Insurance Renewal and purchase made simple. Get a bike insurance quote now!";
  //     break;

  //   case COMMON_ROUTES.PRODUCT_TERM:
  //     title = "Buy Best Term Life Insurance Policy";
  //     description =
  //       "Explore our Affordable Term Life Insurance options and secure your future with the Best Term Insurance policies. Buy with confidence, knowing you've chosen a reliable Term Life Insurance Policy that fits your needs.";
  //     break;

  //   case COMMON_ROUTES.PRODUCT_HEALTH:
  //     title =
  //       "Compare Health Insurance Plans in India - Best for Family with Cashless Benefits";
  //     description =
  //       "Find the optimal family medical insurance in India. Compare various health insurance plans to secure comprehensive coverage for your family's health and well-being.";
  //     break;

  //   // Add other cases as needed
  //   default:
  //     title = "Get Car, Bike, Health & Life Insurance with JioInsure";
  //     description =
  //       "Get your car, bike, health, or Life insurance online. Comprehensive coverage across India. Book now and get the right quotes! JioInsure - Jio General Insurance";
  //     break;
  // }

  const head = document.getElementsByTagName("head")[0];

  // Set the title
  document.title = title;

  // Set the meta description
  let metaDescription = document.querySelector("meta[name='description']");
  if (!metaDescription) {
    metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    head.appendChild(metaDescription);
  }
  metaDescription.content = description;
}

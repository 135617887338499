import React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "../../../CommonSCSS/CMS.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

interface FAQItem {
  panel: string;
  question: string;
  details: React.ReactNode;
}

const faqs: FAQItem[] = [
  {
    panel: "panel1",
    question: "Who is an insurance broker?",
    details: (
      <ul className="listView">
        <li>
          An insurance broker is a specialist in insurance and risk management.
        </li>
        <li>
          Brokers act on behalf of their clients and provide advice in the
          interests of their clients.
        </li>
        <li>
          A broker will help you identify your individual and/or business risks
          to help you decide what to insure, and how to manage those risks in
          other ways.
        </li>
        <li>
          Insurance brokers can give you technical advice that can be very
          useful if you need to make a claim.
        </li>
        <li>
          Brokers are aware of the terms and conditions, coverages and
          exclusions and costs of a wide range of competing insurance policies,
          so they can help you find the most appropriate cover for your own
          circumstances.
        </li>
        <li>
          Brokers can help arrange and place the cover with the chosen insurer.
        </li>
      </ul>
    ),
  },
  {
    panel: "panel2",
    question: "Why use an insurance broker?",
    details: (
      <ul className="listView">
        <li>
          Some insurance policies including health insurance policies can be
          complicated and not easily comparable. An insurance broker can help
          you understand the details of a policy and also work out what level of
          cover you need, so that you can make sure you are properly protected.
        </li>
        <li>
          Brokers can often find you a good deal on insurance because they have
          a thorough understanding of the insurance market and can negotiate
          premiums on your behalf.
        </li>
        <li>
          A broker will explain your policy to you and advise you if there are
          any special situations you need to know about.
        </li>
        <li>
          Brokers can prepare a customised insurance and risk management program
          for you or your business, where they design the policies, negotiate
          the terms with insurance companies and place the cover with the
          insurer.
        </li>
        <li>
          By including a risk management program, which puts some of the
          responsibility for risk prevention and loss minimisation on you or
          your business, you can reduce premium costs.
        </li>
        <li>
          Using the broker does not cost more than going direct as they are paid
          the brokerage by the Insurers for selling their products.
        </li>
        <li>
          If you need to make a claim on your policy, your broker will assist
          you through the process and will liaise with the insurer on your
          behalf.
        </li>
      </ul>
    ),
  },
  {
    panel: "panel3",
    question: "Why Ahalia Insurance?",
    details: (
      <ul className="listView">
        <li>
          Go beyond broking to become an insurance advisor with the highest
          ethical and professional standards.
        </li>
        <li>
          We believe in a knowledge-based approach that studies and analyses the
          client's current business situation.
        </li>
        <li>
          We identify the specific risk exposure and offer customised solutions
          with the highest quality coverage and service at competitive rates.
        </li>
        <li>Help make insurance understandable to clients.</li>
        <li>Provide outstanding claims service.</li>
        <li>
          Become a partner who brings in value addition rather than just being
          an insurance broker with a transactional relationship.
        </li>
        <li>
          We aim to be a “One-Stop” insurance center with a dynamic, energetic,
          and forward-thinking team of trained professionals who believe in
          delivering outstanding customer service and the most relevant
          insurance solutions.
        </li>
        <li>
          We always strive our level best to ensure that we are not just looking
          for customer service but, for customer delight. Whether you decide to
          get in touch with us over the phone, via the internet, or in person,
          we are dedicated to providing personal service of unmatched quality
          with utmost care and transparency.
        </li>
        <li>
          At AIBPL, we offer a wide spectrum of life and health insurance
          policies, employee benefits, investment and risk management products
          and services to individuals, businesses, and corporates.
        </li>
        <li>
          We have the scale, depth of expertise and experience to identify and
          meet the uniquely complex demands of India's leading companies. We are
          here to help you uncover and evaluate risks to enable you to identify
          the most appropriate insurance solutions tailored to your needs.
        </li>
      </ul>
    ),
  },
  {
    panel: "panel4",
    question: "Why should I purchase insurance through policyfirst.com?",
    details: (
      <>
        <p className="para">
          Policyfirst provides a wide range of options to choose from along with
          the advantages and disadvantages of the policies. This helps you in
          taking an informed decision. Policyfirst also supports the customers
          in the event of a Claim.
        </p>
        <p className="para">
          Online Insurance policies might be cheaper due to low distribution
          cost. Other advantages of Policyfirst are:
        </p>
        <ul className="numberList">
          <li>
            On Policyfirst.com, you can calculate the quotes yourself by
            selecting the sum insured and coverages and find out the best
            suitable option fitting into your budget.
          </li>
          <li>
            On Policyfirst.com, you can get quotes from multiple insurers with
            similar coverages which helps you to take a well informed decision
            from the comforts of your home.
          </li>
          <li>
            Since all details are filled by the customer itself, there is no
            worry about any misrepresentation by a third party which might
            result in repudiation of a claim in the future.
          </li>
        </ul>
      </>
    ),
  },
  {
    panel: "panel5",
    question: "How can I resolve my queries online?",
    details: (
      <>
        <p className="para">
          For any Query or Issues related to Policyfirst.com, you can
        </p>
        <ul className="listView">
          <li>Call our helpline number or</li>
          <li>Whatsapp Chat on Customer Support Number</li>
          <li>Chat on the portal</li>
          <li>Send a mail to info@ahaliainsurance.com</li>
        </ul>
      </>
    ),
  },
  {
    panel: "panel6",
    question:
      "How convenient is it to take an online policy on policyfirst.com?",
    details: (
      <p className="para">
        The portal has been developed by veterans from the insurance industry
        who clearly understand the difficulties of the customers while taking an
        insurance policy. You can issue a policy through Policyfirst.com in less
        than 5 minutes. Generally, motor and health insurance policies are
        issued immediately on receipt of payment. Some policies require
        underwriting approval and hence might be issued after premium payment
        and post underwriting approval. This is very common in the case of life
        insurance policies and health insurance policies for persons above a
        certain specified age or for those with pre-existing
        illness/disease/conditions.
      </p>
    ),
  },
  {
    panel: "panel7",
    question: "Is online insurance more transparent and secure?",
    details: (
      <>
        <p className="para">
          Yes. Through policyfirst.com, Ahalia Insurance Brokers give options of
          multiple insurers and multiple products, whereas an individual agent
          can sell specific products since he can offer the product of only one
          insurance company. Online Platform is safer and more transparent.
        </p>
        <p className="para">
          Since the payment of premium is done by the customer directly to the
          Insurance Company through online mode. So, there is complete
          transparency online and no misrepresentations or frauds can occur.
        </p>
        <ul className="numberList">
          <li>
            No Financial Fraud, since payment redirected directly to Insurer.
          </li>
          <li>
            No Product Misguidance, since all product details are provided and
            product comparison can be done by the customer directly.
          </li>
        </ul>
      </>
    ),
  },
  {
    panel: "panel8",
    question: "How secure is policyfirst.com?",
    details: (
      <>
        <p className="para">
          Online Platforms for Insurance are constructed in adherence to the
          IRDAI Guidelines
        </p>
        <ul className="numberList">
          <li>
            Apart from the pre-audit, the processes at Policyfirst.com is
            audited regularly. Under the ISNP Guidelines, IRDAI requires the
            brokers to conduct mandatory audit of DISA/ CISA/ CERT.
          </li>
          <li>The data is secured on Cloud hosted by reputed providers.</li>
          <li>
            High end Firewall system is used to prevent loss of data through
            hacking.
          </li>
          <li>
            The website has SSL Certification. Secure Website starts from
            “HTTPS” and so our website is secure.
          </li>
          <li>
            The payment is remitted directly to the insurer through the payment
            gateway of the insurance companies which is also maintained with
            high security processes.
          </li>
        </ul>
      </>
    ),
  },
];

function FAQ() {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Box className="mainWrapper">
        {isMobile ? <MNavbar /> : <Navbar />}
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12}>
            <h2 className="pageHeading">Frequently Asked Questions</h2>
          </Grid>
          <Grid xs={12}>
            {faqs.map((faq) => (
              <Accordion
                key={faq.panel}
                expanded={expanded === faq.panel}
                onChange={handleChange(faq.panel)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === faq.panel ? (
                      <RemoveCircleIcon />
                    ) : (
                      <AddCircleIcon />
                    )
                  }
                  aria-controls={`${faq.panel}bh-content`}
                  id={`${faq.panel}bh-header`}
                >
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingLeft: "0px" }}>
                  {faq.details}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </Box>
  );
}

export default FAQ;

import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../../SupportingFiles/colours";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function TermProductPage() {
  const isMobile = useIsMobile();
  return (
    <Box>
      <Box className="mainWrapper">
        {isMobile ? <MNavbar /> : <Navbar />}
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12}>
            <h2 className="pageHeading">Term Insurance</h2>
          </Grid>
          <Grid xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
            <p className="para">
              Taking into account the low penetration level of life insurance in
              India, We at AIBPL offer wide range of products and solutions
              addressing the diverse insurance needs of each and every segment
              of the society. Our team comes has the relevant expertise and
              market understanding to cater to the requirements of individuals
              and institutions alike.
            </p>
            <p className="para">
              Given below are the basic types of life insurance policies based
              on customer segmentation . All life insurance policies are built
              around these basic insurance policies by combination of various
              other features.
            </p>
          </Grid>
          <Grid xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }}>
            <Box
              border="1px solid #FF5100"
              borderRadius="12px"
              padding="12px"
              textAlign="center"
            >
              <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
                Best Term Insurance Plan
              </h5>
              <Grid container columnSpacing={2} rowSpacing={3}>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    value={"value"}
                    attrName={["name"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={" Enter full name"}
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Annual Income"}
                    value={""}
                    attrName={["income"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={"Select income"}
                    data={[]}
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Smoke?"}
                    value={""}
                    attrName={["smoke"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={"Select input"}
                    data={[]}
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Gender"}
                    value={""}
                    attrName={["gender"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={"Select input"}
                    data={[]}
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"DOB"}
                    value={""}
                    attrName={["dob"]}
                    value_update={() => {}}
                    error_message="Enter DOB"
                    warn_status={false}
                    // date_validation_type="MONTHS"
                    // min_date={1200}
                    // max_date={216}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Pincode"}
                    value={"value"}
                    attrName={["pincode"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={" Enter full name"}
                    max_length={6}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={"value"}
                    attrName={["enterMobile"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={" Enter mobile"}
                    max_length={10}
                  />
                </Grid>
                <Grid xs={12}>
                  <CustomButton
                    text_name="View Quotes"
                    class_name="mediumSecondaryBtn"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12} md={8}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Individual
            </h5>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Term Insurance Policy
            </h5>
            <ul className="listView">
              <li>
                A term assurance cover provides financial benefit to the
                beneficiary in the event of the death of the policyholder For
                instance, if a person buys a cover for INR 10 lakh for5 years,
                the beneficiary is entitled to the said amount if the
                policyholder dies within 5 years. However premium paid is not
                refundable if the policyholder survives the entire period.
              </li>
              <li>
                These insurance policies are designed to provide 100 per cent
                risk cover and hence they do not have any additional charges
                other than the basic ones. This makes premiums paid under such
                life insurance policies the lowest in the life insurance
                category.
              </li>
            </ul>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Whole Life Policy
            </h5>
            <ul className="listView">
              <li>
                A whole life policy covers a policyholder against death,
                throughout his life term. The advantage that an individual gets
                when he / she opts for a whole life policy is that the validity
                of this life insurance policy is not defined and hence the
                individual enjoys the life cover throughout his or her life.
              </li>
              <li>
                Under this life insurance policy, the policyholder pays regular
                premiums until his death, upon which the corpus is paid to the
                family. The policy does not expire till the time any unfortunate
                event occurs with the individual.
              </li>
              <li>
                Increasingly, whole life policies are being combined with other
                insurance products to address a variety of needs such as
                retirement planning, etc.
              </li>
              <li>
                Premiums paid under the whole life policies are tax exempt.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={4}>
            <img src="../images/term-product.svg" width="100%" alt="img" />
          </Grid>
          <Grid xs={12}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Endowment Policy
            </h5>
            <ul className="listView">
              <li>
                Combining risk cover with financial savings, endowment policies
                are among the popular life insurance policies.
              </li>
              <li>
                Policy holders benefit in two ways from a pure endowment
                insurance policy. In case of death during the tenure, the
                beneficiary gets the sum assured. If the individual survives the
                policy tenure, he gets back the premiums paid with other
                investment returns and benefits like bonuses.
              </li>
              <li>
                In addition to the basic policy, insurers offer various benefits
                such as double endowment and marriage/ education endowment
                plans.
              </li>
              <li>
                The concept of providing the customers with better returns has
                been gaining importance in recent times. Hence, insurance
                companies have been coming out with new and better ULIP versions
                of endowment policies. Under such life insurance policies the
                customers are also provided with an option of investing their
                premiums into the markets, depending on their risk appetite,
                using various fund options provided by the insurer, these life
                insurance policies help the customer profit from rising markets.
              </li>
              <li>
                The premiums paid and the returns accumulated through pure
                endowment policies and their ULIP variants are tax exempt.
              </li>
            </ul>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Money Back Policy
            </h5>
            <ul className="listView">
              <li>
                This life insurance policy is favoured by many people because it
                gives periodic payments during the term of policy. In other
                words, a portion of the sum assured is paid out at regular
                intervals. If the policy holder survives the term, he gets the
                balance sum assured.
              </li>
              <li>
                In case of death during the policy term, the beneficiary gets
                the full sum assured.
              </li>
              <li>
                New ULIP versions of money back policies are also being offered
                by various life insurers.
              </li>
              <li>
                The premiums paid and the returns accumulated though a money
                back policy or its ULIP variants are tax exempt.{" "}
              </li>
            </ul>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              ULIPs
            </h5>
            <ul className="listView">
              <li>
                ULIPs are market-linked life insurance products that provide a
                combination of life cover and wealth creation options.
              </li>
              <li>
                A part of the amount that people invest in a ULIP goes toward
                providing life cover, while the rest is invested in the equity
                and debt instruments for maximising returns.
              </li>
              <li>
                ULIPs provide the flexibility of choosing from a variety of fund
                options depending on the customers risk appetite. One can opt
                from aggressive funds (invested largely in the equity market
                with the objective of high capital appreciation) to conservative
                funds (invested in debt markets, cash, bank deposits and other
                instruments, with the aim of preserving capital while providing
                steady returns).
              </li>
              <li>
                ULIPs can be useful for achieving various long-term financial
                goals such as planning for retirement, child’s education,
                marriage etc.
              </li>
            </ul>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Annuities and Pension
            </h5>
            <ul className="listView">
              <li>
                In an annuity scheme, the insurer agrees to pay the policyholder
                a stipulated amount of money at frequent intervals. The
                objective is to provide the annuitant with income protection
                during his or her retirement life through periodic pension
                payments. Annuities can be broadly classified as immediate and
                deferred. In India based on individual needs , different types
                of annuity schemes are offered by various insurance companies .
              </li>
              <ul className="ml-6">
                <li> Life annuity </li>
                <li> Life annuity with return of capital </li>
                <li>
                  {" "}
                  Life annuity guaranteed for life (5, 10, 15 and 20 years){" "}
                </li>
                <li> Joint life last survivor </li>
                <li>
                  {" "}
                  Joint life last survivor with return of capital ( at 50% and
                  100%){" "}
                </li>
              </ul>
            </ul>
          </Grid>
        </Grid>
        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </Box>
  );
}

export default TermProductPage;

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://c6948c2a4b99484d003edc52f101c84b@o4506864219521024.ingest.us.sentry.io/4507526972702720",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <ToastContainer theme="colored" />
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

  reportWebVitals();
}

renderApp();

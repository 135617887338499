import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import TextArea from "../../../Component/Common/FieldTypes/TextArea/TextArea";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";

function Career() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}
      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="careerHeading">Opportunities</h2>
        </Grid>
        <Grid xs={12} md={6}>
          <h3>Join us for fast forwarding your career </h3>
          <p
            style={{
              color: "rgba(0, 0, 0, 0.56)",
              marginTop: "8px",
              lineHeight: "24px",
            }}
          >
            We encourage your ideas and opinions and a space to develop on your
            own. Contribution and talent gets recognised beyond boundaries.
          </p>
          <img
            src="../images/join-us.svg"
            alt="img"
            width="100%"
            style={{ maxWidth: "360px", margin: "24px 0px" }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Full Name"}
                  value={"value"}
                  attrName={["name"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={" Enter full name"}
                  max_length={60}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={"value"}
                  attrName={["enterMobile"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={" Enter mobile"}
                  max_length={10}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Current Location"}
                  value={"value"}
                  attrName={["enterEmail"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Enter Email"}
                  max_length={50}
                />
              </Grid>

              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Tell me something we don't know"}
                  value={""}
                  attrName={["interested"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Select input"}
                />
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" gap="12px">
                <CustomButton
                  text_name="Resume"
                  class_name="regularPrimaryBtn"
                />
                <CustomButton
                  text_name="Submit Resume"
                  class_name="regularSecondaryBtn"
                />
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid xs={12}>
          <h3>Current Openings</h3>
          <ul className="openings">
            <li>
              <Box
                height="76px"
                width="76px"
                borderRadius="100px"
                margin="16px auto"
                padding="20px 14px 12px"
                sx={{ background: "#53a4f2" }}
              >
                <img src="../images/man-icon.svg" width="100%" />
              </Box>
              <p>
                Position: <span>Executive - Operation</span>
              </p>
              <p>
                Location: <span>Kochi, Kerala</span>
              </p>
              <Box
                sx={{
                  background: "#53a4f2",
                  color: "#fff",
                  padding: "12px",
                  textAlign: "center",
                }}
              >
                <h6>Roles and Responsibilities</h6>
                <ul>
                  <li>
                    Handling all online and offline cases with proper
                    documentation
                  </li>
                  <li>
                    Ensuring all the deliverables are attended and closed within
                    the standard TAT to optimise operational efficiency.
                  </li>
                  <li>
                    Quality check of documents and updating the same in system.
                  </li>
                  <li>
                    Getting the endorsements done with proper maintenance of
                    records on system.
                  </li>
                </ul>
                <h6 className="mt-3">Key Requirements</h6>
                <ul>
                  <li>
                    Must have knowledge on insurance products – Motor, Health
                    and Life and should be a graduate
                  </li>
                </ul>
              </Box>
            </li>
            <li>
              <Box
                height="76px"
                width="76px"
                borderRadius="100px"
                margin="16px auto"
                padding="20px 14px 12px"
                sx={{ background: "#af9aff" }}
              >
                <img src="../images/man-icon.svg" width="100%" />
              </Box>
              <p>
                Position: <span>Manager - Business Development </span>
              </p>
              <p>
                Location: <span>Kochi, Kerala</span>
              </p>
              <Box
                sx={{
                  background: "#af9aff",
                  color: "#fff",
                  padding: "12px",
                  textAlign: "center",
                }}
              >
                <h6>Roles and Responsibilities</h6>
                <ul>
                  <li>
                    Hire Point of Sales Persons (POSP) to associate with the
                    Company to bring new customers
                  </li>
                  <li>
                    Provide product and process training to the POSPs - Provide
                    necessary support to enable the POSPs to perform and earn.
                  </li>
                  <li>
                    Deliver on business goals set by the Company through the
                    POSPs
                  </li>
                  <li>
                    Liaison with the Operation team to ensure timely response to
                    the requests from the POSPs
                  </li>
                </ul>
                <h6 className="mt-3">Key Requirements</h6>
                <ul>
                  <li>
                    Should have own two wheeler with a valid driving license
                  </li>
                </ul>
              </Box>
            </li>
          </ul>
        </Grid>
      </Grid>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Career;

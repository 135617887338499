import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../../SupportingFiles/colours";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";

function Cancellation() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}

      <Grid container spacing={3} className="row mb-10">
        <Grid xs={12}>
          <h2 className="pageHeading">Cancellation & Refund</h2>
        </Grid>
        <Grid xs={12}>
          <p className="para">
            The Cancellation and Refund policy depends on the terms and
            conditions of the product you have bought and might vary from one
            insurer to another. Generally, the process for cancellation of the
            policy and/or the refund of the premium is as follows:
          </p>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Cancellation of the policy by you
          </h5>

          <p className="para">
            If you are not satisfied with the terms and conditions in your
            policy, you can cancel it any time before the renewal date. To
            initiate a cancellation, you need to write an application to the
            insurance company stating the reason for cancelling the policy. The
            insurance company or insurer referred to here is the company whose
            policy you have purchased. Depending on when you have applied for
            cancellation your request will be processed. The period of
            cancellation will be considered as the time when the insurance
            company receives the written request.
          </p>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Cancellation within the free-look period
          </h5>

          <p className="para">
            Free-look period is a 15-day period post purchase of a health or
            life insurance policy, that most insurance companies give their
            customers to review the policy and cancel it if required. If you
            request a cancellation of your policy within the free-look period,
            you get a refund of your premium excluding:
          </p>
          <ul className="listView">
            <li>Health check-up expenses </li>
            <li>Stamp duty </li>
            <li>
              The proportionate risk premium of the period for which you were
              covered
            </li>
          </ul>
          <p>
            Also, please note that the free-look provision is not available for
            renewals.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Cancellation Beyond Free-Look Period
          </h5>

          <p className="para">
            If you apply for cancellation of the policy beyond the free-look
            period then the policy cancellation will be processed as per the
            underwriting of the policy. Also, the amount of premium refunded
            will be equal to what has been defined in the cancellation clause of
            your policy wordings. Most of the policies have a short period scale
            mentioned as part of the policy wordings and hence the customer will
            not be eligible for refund on pro rata basis.
          </p>
          <p className="para">
            If you wish to cancel a policy you have purchased from our portal,
            you can contact us over the phone or on email and we will assist you
            through the process.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Cancellation by the Insurance Company
          </h5>

          <p className="para">
            The insurance company has the right to cancel your policy in the
            event of fraud, misrepresentation, non-disclosure of important facts
            or non-cooperation by the insured members. In such cases, the
            insurance company will give notice of 30-days and the premium will
            not be refunded.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Refund of the Premium
          </h5>

          <p className="para">
            The premium that you pay is collected directly by the insurance
            company. If you claim for a refund because of an incorrect
            transaction or policy cancellation, you will get an amount as per
            the insurance company's policy. The refunded amount will be given to
            you in the form of a cheque or direct fund transfer, as per the
            insurance company's policy.
          </p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Cancellation;

import React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import { COLORS } from "../../../SupportingFiles/colours";
import TextArea from "../../../Component/Common/FieldTypes/TextArea/TextArea";
import SelectDropdown from "../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function Claim() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}
      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="claimHeading">Need a Claim?</h2>
        </Grid>
        <Grid xs={12} md={6}>
          <h3>Facing Problems</h3>
          <ul
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "24px",
              margin: "40px 0px",
            }}
          >
            <li>
              <img src="../images/call-icon.svg" alt="img" height="50px" />
              <h5 style={{ color: COLORS.secondary, marginTop: "8px" }}>
                For instant claim, Feel free to talk to us:
              </h5>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "12px" }}>
                +91 90723-66668
              </p>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "12px" }}>
                +91 484 401 9797
              </p>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "12px" }}>
                Email ID:{" "}
                <Link
                  style={{
                    color: "rgba(0, 0, 0, 0.56)",
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "14px",
                    display: "inline-block",
                    marginTop: "8px",
                  }}
                  href="mailto:info@ahaliainsurance.com"
                >
                  info@ahaliainsurance.com
                </Link>
              </p>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "12px" }}>
                Address: 66/598, First Floor, Beejay Towers, Rajaji Road, Kochi,
                Kerala - 682035
              </p>
            </li>
          </ul>
        </Grid>
        <Grid xs={12} md={6} textAlign="center">
          <>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Full Name"}
                  value={"value"}
                  attrName={["name"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={" Enter full name"}
                  max_length={60}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={"value"}
                  attrName={["enterMobile"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={" Enter mobile"}
                  max_length={10}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={"value"}
                  attrName={["enterEmail"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Enter Email"}
                  max_length={50}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title={"Insurer"}
                  value={""}
                  attrName={["interested"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Select input"}
                  data={[]}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Policy Number"}
                  value={"value"}
                  attrName={["enterEmail"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Enter Email"}
                  max_length={50}
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title={"Claim for"}
                  value={""}
                  attrName={["interested"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Select input"}
                  data={[]}
                />
              </Grid>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Describe your problem"}
                  value={""}
                  attrName={["interested"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Select input"}
                />
              </Grid>
              <Grid xs={12}>
                <CustomButton
                  text_name="Submit Claim"
                  class_name="regularSecondaryBtn mb-10"
                />
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Claim;

import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import TextArea from "../../../Component/Common/FieldTypes/TextArea/TextArea";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function Complaint() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}
      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="complaintHeading">File a complaint</h2>
        </Grid>
        <Grid xs={12} md={6}>
          <h3>Having Troubles</h3>
          <img
            src="../images/complaint-img.svg"
            alt="img"
            width="100%"
            style={{ maxWidth: "360px", margin: "24px 0px" }}
          />

          <ul
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "24px",
              margin: "40px 0px",
            }}
          >
            <li style={{ minWidth: "40%" }}>
              <h5>Phone:</h5>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "8px" }}>
                +91 97786-24836
              </p>
            </li>
            <li style={{ minWidth: "40%" }}>
              <h5>E-mail:</h5>
              <Link
                style={{
                  color: "rgba(0, 0, 0, 0.56)",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "14px",
                  display: "inline-block",
                  marginTop: "8px",
                }}
                href="mailto:info@ahaliainsurance.com"
              >
                info@ahaliainsurance.com
              </Link>
            </li>
            <li style={{ minWidth: "40%" }}>
              <h5>Address:</h5>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "8px" }}>
                66/598, First Floor, Beejay Towers, Rajaji Road, Kochi, Kerala -
                682035
              </p>
            </li>
          </ul>
        </Grid>
        <Grid xs={12} md={6} textAlign="center">
          <>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Full Name"}
                  value={"value"}
                  attrName={["name"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={" Enter full name"}
                  max_length={60}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={"value"}
                  attrName={["enterMobile"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={" Enter mobile"}
                  max_length={10}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={"value"}
                  attrName={["enterEmail"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Enter Email"}
                  max_length={50}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title={"Insurer"}
                  value={""}
                  attrName={["interested"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Select input"}
                  data={[]}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Policy Number"}
                  value={"value"}
                  attrName={["enterEmail"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Enter Email"}
                  max_length={50}
                />
              </Grid>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Describe your problem"}
                  value={""}
                  attrName={["interested"]}
                  value_update={() => {}}
                  warn_status={false}
                  error_message={"Select input"}
                />
              </Grid>
              <Grid xs={12}>
                <CustomButton
                  text_name="Submit Complaint"
                  class_name="regularSecondaryBtn mb-10"
                />
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Complaint;

import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomeContainer from "../../../Container/Home/HomeContainer";
import { changeTitleAndMeta } from "../../../SupportingFiles/faviconUtils";
import Contact from "../../../Pages/Desktop/Contact/Contact";
import Claim from "../../../Pages/Desktop/Claim/Claim";
import Complaint from "../../../Pages/Desktop/Complaint/Complaint";
import Aboutus from "../../../Pages/Desktop/Aboutus/Aboutus";
import Disclaimer from "../../../Pages/Desktop/Disclaimer/Disclaimer";
import TermsAndConditions from "../../../Pages/Desktop/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../../../Pages/Desktop/PrivacyPolicy/PrivacyPolicy";
import Cancellation from "../../../Pages/Desktop/Cancellation/Cancellation";
import { COMMON_ROUTES } from "../../Path/CommonRoutes";
import HealthProductPage from "../../../Pages/Desktop/HealthProductPage/HealthProductPage";
import CarProductPage from "../../../Pages/Desktop/CarProductPage/CarProductPage";
import BikeProductPage from "../../../Pages/Desktop/BikeProductPage/BikeProductPage";
import TermProductPage from "../../../Pages/Desktop/TermProductPage/TermProductPage";
import FAQ from "../../../Pages/Desktop/FAQ/FAQ";
import Career from "../../../Pages/Desktop/Career/Career";

function CommonRoutes() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    changeTitleAndMeta();
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route path={"/"} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<Aboutus />} />
        <Route path={COMMON_ROUTES.CONTACT_US} element={<Contact />} />
        <Route path={COMMON_ROUTES.CLAIMS} element={<Claim />} />
        <Route path={COMMON_ROUTES.COMPLAINT} element={<Complaint />} />
        <Route path={COMMON_ROUTES.CAREER} element={<Career />} />
        <Route path={COMMON_ROUTES.FAQ} element={<FAQ />} />
        <Route path={COMMON_ROUTES.TERM_AND_CONDTION} element={<TermsAndConditions />} />
        <Route path={COMMON_ROUTES.DISCLAIMER} element={<Disclaimer />} />
        <Route path={COMMON_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route
          path={COMMON_ROUTES.REFUND_AND_CANCELLATION}
          element={<Cancellation />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarProductPage />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<BikeProductPage />} />
        <Route
          path={COMMON_ROUTES.PRODUCT_TERM}
          element={<TermProductPage />}
        />
      </Routes>
    </div>
  );
}

export default CommonRoutes;

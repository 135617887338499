import { useState } from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../../SupportingFiles/colours";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchDropdown from "../../../Component/Common/FieldTypes/SearchDropdown/SearchDropdown";
import DatePicker from "../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function CarProductPage() {
  const isMobile = useIsMobile();
  const [pageStatus, setPageStatus] = useState<number>(1);
  return (
    <Box>
      <Box className="mainWrapper">
        {isMobile ? <MNavbar /> : <Navbar />}

        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12}>
            <h2 className="pageHeading">Car Insurance</h2>
          </Grid>
          <Grid xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
            <p className="para">
              The statistics of road accidents in India is alarming. Even an
              experienced driver is not impervious to the perils of driving. A
              car insurance not only covers personal damages, but also the costs
              arising from damages to third party. Nowadays, cars are among the
              heaviest investments of an individual. For a fixed amount of
              premium, a car insurance will mitigate all financial risks that
              could arise from operating your vehicle. Your Car insurance can be
              either a Comprehensive Package policy covering lot of perils as
              against a Third Party or Liability only policy which covers damage
              caused by your vehicle to a third party property or death or
              injury caused to a third party by your vehicle.
            </p>
          </Grid>

          <Grid xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }}>
            <Box
              border="1px solid #FF5100"
              borderRadius="12px"
              padding="12px"
              textAlign="center"
              minHeight="300px"
            >
              {/* Step 1 */}
              {pageStatus === 1 ? (
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid xs={12}>
                    <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
                      Best Car Insurance Plan
                    </h5>
                  </Grid>
                  <Grid xs={12}>
                    <Box maxWidth="220px" margin="8px auto">
                      <RKTextField
                        class_name="inputField vehicleNumberField"
                        title={""}
                        value={""}
                        attrName={"reg_no"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter valid vehicle no."}
                        placeholder={"DL01ABC1234"}
                        validation_type="ALL_CAPS"
                        max_length={11}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}

              {/* To go back */}
              {pageStatus !== 1 ? (
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid xs={12}>
                    <Link className="backStep" onClick={() => {}}>
                      <ArrowBackIosNewIcon />
                    </Link>
                  </Grid>
                  <Grid xs={12}>
                    <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
                      {pageStatus === 1 || pageStatus === 3 ? (
                        <>
                          Vehicle <span>Details</span>
                        </>
                      ) : pageStatus === 2 ? (
                        <>
                          Select Your <span>RTO</span>
                        </>
                      ) : pageStatus === 4 ? (
                        <>
                          Policy <span>Details</span>
                        </>
                      ) : null}
                    </h5>
                  </Grid>
                </Grid>
              ) : null}

              {/* Step 2 */}
              {pageStatus === 2 ? (
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid xs={12}>
                    <SearchDropdown
                      class_name="inputField"
                      title="RTO/City Name"
                      value={""}
                      attrName={"rto"}
                      value_update={() => {}}
                      data={[]}
                      warn_status={false}
                      error_message={"Select RTO/City Name"}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {/* Step 3 */}
              {pageStatus === 3 ? (
                <Grid
                  container
                  columnSpacing={{ xs: 2, md: 3 }}
                  rowSpacing={3}
                  justifyContent="center"
                >
                  <Grid xs={12}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Make & Model"
                      value={""}
                      attrName={"make_model"}
                      value_update={() => {}}
                      data={[]}
                      warn_status={false}
                      error_message={"Select Make & Model"}
                    />
                  </Grid>
                  <Grid xs={12} sm={5}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Fuel Type"
                      value={""}
                      attrName={"fuel_type"}
                      value_update={() => {}}
                      data={[]}
                      warn_status={false}
                      error_message={"Select Fuel Type"}
                    />
                  </Grid>
                  <Grid xs={12} sm={7}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Variant"
                      value={""}
                      attrName={"variant_cc"}
                      value_update={() => {}}
                      data={[]}
                      warn_status={false}
                      error_message={"Select variant"}
                    />
                  </Grid>
                  {/* {formData.business_type === "Rollover" ? ( */}
                  <>
                    <Grid xs={12} sm={6} lg={4}>
                      <DatePicker
                        class_name="inputField datepicker"
                        title={"Reg. Date"}
                        value={""}
                        attrName={"reg_date"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Select Reg. Date"}
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                      />
                    </Grid>
                    <Grid xs={12} sm={6} lg={4}>
                      <DatePicker
                        class_name="inputField datepicker"
                        title={"Policy Expiry"}
                        value={""}
                        attrName={"policy_expiry_date"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Select Policy Expiry Date"}
                        // min_date={
                        //   calculateAgeInDays(`${ADD_FORM.reg_date.value}`) -
                        //   89
                        // }
                        max_date={-89}
                        date_validation_type="DAYS"
                      />
                    </Grid>
                    <Grid xs={12} sm={6} lg={4}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Previous Insurer"
                        value={""}
                        attrName={"previous_insurer"}
                        value_update={() => {}}
                        data={[]}
                        warn_status={false}
                        error_message={"Select Previous Insurer"}
                      />
                    </Grid>
                  </>
                  {/* ) : null} */}
                </Grid>
              ) : null}

              <Grid container spacing={3}>
                <Grid xs={12}>
                  <CustomButton
                    text_name="Continue"
                    class_name="mediumSecondaryBtn"
                  />
                </Grid>
                <Grid xs={12}>
                  <Link>Bought a New Car</Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12} md={8}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              A Comprehensive Package Policy covers:
            </h5>
            <h6 style={{ color: COLORS.secondary }}>
              1. Own damage to the vehicle caused by:
            </h6>
            <ul className="listView">
              <li>Accident external means</li>
              <li>Burglary, housebreaking or theft</li>
              <li>Fire, explosion, self-ignition, lighting</li>
              <li>Terrorism, riots, strike or malicious act</li>
              <li>Transit by road, rail, inland waterway, lift</li>
              <li>Earthquake, flood, storm, landslide, rockslide</li>
              <li>NCB and NCB Protection</li>
              <li>Emergency Assistance</li>
              <li>0% depreciation</li>
            </ul>
            <h6 style={{ color: COLORS.secondary }}>
              2. Personal accident cover:
            </h6>
            <p className="para">
              Which covers the cost of medical treatments of the passengers in
              the vehicle in the event of an accident.
            </p>
            <h6 style={{ color: COLORS.secondary, marginTop: "12px" }}>
              3. Third Party Legal Liability:
            </h6>
            <ul className="listView">
              <li>Accidental death or injury to any third party</li>
              <li>Any damage to property owned by third party</li>
            </ul>
            <h6 style={{ color: COLORS.secondary, marginTop: "12px" }}>
              4. Add-on Covers:
            </h6>
            <ul className="listView">
              <li>Emergency Assistance</li>
              <li>NCB Protection</li>
              <li>Nil depreciation cover</li>
              <li>Consummables Cover</li>
              <li>Engine and Gearbox Protection</li>
              <li>Key Loss Protection</li>
              <li>Return to Invoice (RTI) cover</li>
            </ul>
          </Grid>
          <Grid xs={12} md={4}>
            <img src="../images/car-product.svg" width="100%" alt="img" />
          </Grid>
          <Grid xs={12} md={6}>
            <img src="../images/motor-chart.svg" alt="img" width="100%" />
          </Grid>
          <Grid xs={12} md={6}>
            <img src="../images/motor-img.svg" alt="img" width="100%" />
          </Grid>
          <Grid xs={12} md={6}>
            <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
              Motor Insurance Policy excludes
            </h5>
            <ul className="listView">
              <li>General aging, wear and tear</li>
              <li>Damage by a person driving without a valid license</li>
              <li>Mechanical or electrical breakdown, failure</li>
              <li>
                Damage by a person driving under the influence of liquor or
                drugs
              </li>
              <li>Depreciation, any consequential loss</li>
              <li>Loss/damage attributable to war, mutiny, nuclear risks</li>
              <li>
                Damage to tyres and tubes, unless damaged during an accident
              </li>
              <li>Loss/damage outside India</li>
              <li>Damage to a vehicle given on rent/hire basis</li>
            </ul>
          </Grid>
          <Grid xs={12} md={6}>
            <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
              Type of Claims Settlement
            </h5>
            <ul className="listView">
              <li>Cashless</li>
              <li>Reimbursement</li>
            </ul>
          </Grid>
        </Grid>
        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </Box>
  );
}

export default CarProductPage;
